import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Filter, Truck, ChevronDown, Loader2 } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { ProductCard } from "./ProductCard";
import { FilterPanel } from "./FilterPanel";

export default function SearchComponent() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var category = queryParams.get("category");

  const brand = queryParams.get("brand");
  const state = queryParams.get("state");
  const condition = queryParams.get("condition");

  // // This condition seems unnecessary as category is assigned `null` after this.
  // if (category) {
  //   category = null;
  // }

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, [products]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          brand: brand,
          state: state,
          category: category, 
          condition: condition === "used" ? "old" : condition,
        };

        const response = await axios.get("/api/vehicles", {
          params,
        });
        setProducts(response.data);
      } catch (err) {
        // setFil;
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category, brand]);

  const handleBrandChange = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const handleTypeChange = (category) => {
    setSelectedTypes((prev) =>
      prev.includes(category)
        ? prev.filter((t) => t !== category)
        : [...prev, category]
    );
  };

  const filteredProducts = products.filter((product) => {
    const matchesCategory = category
      ? (category === "Truck" ? "Medium Trucks" : category) ===
        (product.category === "Truck" ? "Medium Trucks" : product.category)
      : true;
    const matchesBrand = selectedBrands.length
      ? selectedBrands.includes(product.brand)
      : true;
    const matchesType = selectedTypes.length
      ? selectedTypes.includes(
          product.category === "Truck" ? "Medium Trucks" : product.category
        )
      : true;
    return matchesCategory && matchesBrand && matchesType;
  });

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-violet-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-red-500">
        <p className="text-lg font-medium">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50" id="#top">
      {/* Header with filter toggle */}
      <div className="sticky top-0 z-50 bg-white shadow-sm border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            {category || "All Vehicles"}
          </h1>
          <button
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-violet-50 text-violet-600 hover:bg-violet-100 transition-colors"
          >
            <Filter className="w-5 h-5" />
            <span>Filters</span>
          </button>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Filter Panel */}
          <AnimatePresence>
            {isFilterOpen && (
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                className="lg:w-64 flex-shrink-0"
              >
                <FilterPanel
                  products={products}
                  selectedBrands={selectedBrands}
                  selectedTypes={selectedTypes}
                  onBrandChange={handleBrandChange}
                  onTypeChange={handleTypeChange}
                />
              </motion.div>
            )}
          </AnimatePresence>

          {/* Product Grid */}
          <div className="flex-1">
            {filteredProducts.length == 0 ? (
              <div className="flex flex-col items-center justify-center py-12 px-4">
                <Truck className="w-16 h-16 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  No vehicles found
                </h3>
                <p className="text-gray-500">
                  Try adjusting your filters or search criteria
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {filteredProducts.map((product, index) => (
                  <motion.div
                    key={product._id.$oid}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <ProductCard product={product} />
                  </motion.div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
