import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ChevronDown,
  FileText,
  Trash2,
  RefreshCw,
  AlertCircle,
  CheckCircle2,
  Clock,
  XCircle,
  User,
  Phone,
  Mail,
  MapPin,
  Car,
  DollarSign,
  FileCheck,
  LogOut,
  Eye,
  CheckCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { set } from "react-hook-form";

// Status badge component
const StatusBadge = ({ status }) => {
  const statusConfig = {
    Pending: {
      icon: Clock,
      className: "bg-yellow-100 text-yellow-800 border-yellow-200",
    },
    Discard: {
      icon: XCircle,
      className: "bg-red-100 text-red-800 border-red-200",
    },
    Evaluating: {
      icon: RefreshCw,
      className: "bg-blue-100 text-blue-800 border-blue-200",
    },
    Confirm: {
      icon: CheckCircle2,
      className: "bg-green-100 text-green-800 border-green-200",
    },
  };

  const { icon: Icon, className } =
    statusConfig[status] || statusConfig.Pending;

  return (
    <span
      className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium border ${className}`}
    >
      <Icon size={16} />
      {status}
    </span>
  );
};

// Info Row component
const InfoRow = ({ icon: Icon, label, value }) => (
  <div className="flex items-center justify-between py-2 border-b border-gray-100 last:border-0">
    <div className="flex items-center gap-2 text-gray-600">
      <Icon size={16} />
      <span className="text-sm font-medium">{label}</span>
    </div>
    <span className="text-sm font-semibold text-gray-900">{value}</span>
  </div>
);

const AdminPage = ({ setLoggedIn }) => {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sellRequest, setSellRequest] = useState(false);
  const [buyRequest, setBuyRequest] = useState(true);
  const [visibleVehicle, setVisibleVehicle] = useState(false);
  const [visibleVehiclesData, setVisibleVehiclesData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchInquiries();
  }, []);

  const fetchInquiries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "/api/inquiries"
      );
      setInquiries(response.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch inquiries. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const [hiddenVehicles, setHiddenVehicles] = useState([]);


  const handleProductDelete = async (productId) => {
    // Confirm deletion from the user
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product and its associated inquiries?"
    );
    if (!confirmDelete) return;
  
    // API endpoint to delete the product
    const apiUrl = `/api/products/${productId}`;
  
    try {
      // Make the delete request to the server
      const response = await axios.delete(apiUrl);
  
      // Optionally fetch inquiries again to refresh the list
      await fetchVisibleVehicles();

      alert("Product and associated inquiries deleted successfully.");
    } catch (error) {
      // Log and notify the user of the error
      console.error("Error deleting product:", error);
      alert("Failed to delete the product. Please try again.");
    }
  };

  // Fetch hidden vehicles
  useEffect(() => {
    const fetchHiddenVehicles = async () => {
      try {
        const response = await axios.get(
          "/api/vehicles/hidden"
        );
        if (response.data && response.data.length > 0) {
          setHiddenVehicles(response.data);
        } else {
          setHiddenVehicles([]);
        }
      } catch (error) {
        console.error("Error fetching hidden vehicles:", error);
      }
    };

    fetchHiddenVehicles();
  }, [handleProductDelete]);

  const handleStatusChange = async (inquiryId, status) => {
    try {
      await axios.patch(
        `/api/inquiries/${inquiryId}/status`,
        { status }
      );
      setInquiries((prev) =>
        prev.map((inquiry) =>
          inquiry.inquiry._id === inquiryId
            ? { ...inquiry, inquiry: { ...inquiry.inquiry, status } }
            : inquiry
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
    setActiveDropdown(null);
  };

  const handleDelete = async (inquiryId) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;
    try {
      await axios
        .delete(`/api/inquiries/${inquiryId}`)
      setInquiries((prev) =>
        prev.filter((inquiry) => inquiry.inquiry._id !== inquiryId)
      );
      fetchInquiries();
    } catch (error) {
      console.error("Error deleting inquiry:", error);
    }
  };

  
  

  const handleMarkSold = async (inquiryId) => {
    if (!window.confirm("Are you sure you want to mark this product as sold?"))
      return;

    try {
      // Sending the PATCH request to mark the product as sold
      await axios
        .patch(`/api/mark_sold/${inquiryId}`)

      // Update the inquiries state to reflect the sold status
      setInquiries((prev) =>
        prev.map((inquiry) =>
          inquiry.inquiry._id === inquiryId
            ? { ...inquiry, inquiry: { ...inquiry.inquiry, sold: true } }
            : inquiry
        )
      );

      // Fetch the latest inquiries (optional)
      fetchInquiries();
    } catch (error) {
      console.error("Error marking inquiry as sold:", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex items-center gap-3">
          <RefreshCw className="w-6 h-6 animate-spin text-blue-600" />
          <span className="text-lg font-medium text-gray-700">
            Loading inquiries...
          </span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center space-y-4">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto" />
          <div className="text-lg font-medium text-gray-900">{error}</div>
          <button
            onClick={fetchInquiries}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  const handleMakePublic = async (vehicleId) => {
    try {
      await axios.patch(
        `/api/vehicles/${vehicleId}`
      );
      setHiddenVehicles((prev) =>
        prev.filter((vehicle) => vehicle._id !== vehicleId)
      );
    } catch (error) {
      console.error("Error making vehicle public:", error);
    }
  };

  const fetchVisibleVehicles = async () => {
    try {
      const response = await axios.get(
        "/api/vehicles"
      );
      setVisibleVehiclesData(response.data);
    } catch (error) {
      console.error("Error fetching visible vehicles:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Vehicle Inquiries
          </h1>

          <div className="flex space-x-4">
            <div className="flex space-x-4">
              {/* Sell Requests Button */}
              <button
                onClick={() => {
                  setSellRequest(true);
                  setBuyRequest(false);
                  setVisibleVehicle(false);
                }}
                className="bg-white text-blue-600 py-2 px-6 rounded-lg shadow-md border border-blue-600 hover:bg-blue-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Sell Requests
              </button>
              {/* Buy Requests Button */}
              <button
                onClick={() => {
                  setSellRequest(false);
                  setBuyRequest(true);
                  setVisibleVehicle(false);
                }}
                className="bg-white text-green-600 py-2 px-6 rounded-lg shadow-md border border-green-600 hover:bg-green-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Buy Requests
              </button>
              <button
                onClick={() => {
                  setSellRequest(false);
                  setBuyRequest(false);
                  setVisibleVehicle(true);
                  fetchVisibleVehicles();
                }}
                className="bg-white text-green-600 py-2 px-6 rounded-lg shadow-md border border-green-600 hover:bg-green-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Visible Vehicle
              </button>
            </div>
          </div>

          <div className="flex gap-4">
            <button
              onClick={() => setLoggedIn(false)}
              className="flex items-center gap-2 px-6 py-2 bg-white text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <LogOut size={16} />
              Logout
            </button>
            <button
              onClick={fetchInquiries}
              className="flex items-center gap-2 px-6 py-2 bg-white text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <RefreshCw size={16} />
              Refresh
            </button>
          </div>
        </div>

        {buyRequest && (
          <div className="space-y-6">
            {inquiries.map((inquiry) => (
              <div
                key={inquiry.inquiry._id}
                className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
              >
                <div className="p-6">
                  <div
                    // onClick={}
                    onClick={() => navigate("/product/" + inquiry.vehicle._id)}
                    className="flex items-center cursor-pointer justify-between mb-6"
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={inquiry.vehicle.image?.[0]}
                        alt="Vehicle"
                        className="w-20 h-20 object-cover rounded-lg"
                      />
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900">
                          {inquiry.vehicle.name}
                        </h2>
                        {inquiry?.vehicle?.location?.[1] &&
                          inquiry?.vehicle?.location?.[0] && (
                            <div className="py-2 text-gray-600">
                              <MapPin className="inline-block w-5 h-5 mr-1" />
                              <span>
                                {inquiry?.vehicle?.location?.[1]},{" "}
                                {inquiry?.vehicle?.location?.[0]}
                              </span>
                            </div>
                          )}
                        <p className="text-sm text-gray-500">
                          Inquiry received on{" "}
                          {new Date(
                            inquiry.inquiry.created_at
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <StatusBadge status={inquiry.inquiry.status} />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold text-gray-900">
                        Vehicle Details
                      </h3>
                      <div className="space-y-1">
                        <InfoRow
                          icon={User}
                          label="Owner"
                          value={inquiry.vehicle.ower_info?.name}
                        />
                        <InfoRow
                          icon={Phone}
                          label="Phone"
                          value={inquiry.vehicle.ower_info?.phone}
                        />
                        <InfoRow
                          icon={Mail}
                          label="Email"
                          value={inquiry.vehicle.ower_info?.email}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Address"
                          value={inquiry.vehicle.ower_info?.address}
                        />
                        <InfoRow
                          icon={DollarSign}
                          label="Cost"
                          value={`Rs. ${inquiry?.vehicle?.cost}`}
                        />
                      </div>
                    </div>

                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold text-gray-900">
                        Inquiry Details
                      </h3>
                      <div className="space-y-1">
                        <InfoRow
                          icon={User}
                          label="Name"
                          value={inquiry.inquiry.name}
                        />
                        <InfoRow
                          icon={Phone}
                          label="Phone"
                          value={inquiry.inquiry.phone}
                        />
                        <InfoRow
                          icon={Mail}
                          label="Email"
                          value={inquiry.inquiry.email}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Address"
                          value={inquiry.inquiry.address}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 pt-6 border-t border-gray-100">
                    <div className="flex items-center gap-4">
                      {inquiry.vehicle.documents?.rc === "yes" && (
                        <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition">
                          <FileCheck size={16} />
                          RC Available
                        </div>
                      )}
                      {inquiry.vehicle.documents?.aadhaar === "yes" && (
                        <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition">
                          <FileText size={16} />
                          Aadhaar Available
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-between">
                    {/* Dropdown for updating status */}
                    <div className="mb-10">
                      <div className="absolute">
                        <button
                          onClick={() =>
                            setActiveDropdown(
                              activeDropdown === inquiry._id
                                ? null
                                : inquiry._id
                            )
                          }
                          className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition"
                        >
                          Update Status
                          <ChevronDown
                            size={16}
                            className={`transform transition-transform ${
                              activeDropdown === inquiry._id ? "rotate-180" : ""
                            }`}
                          />
                        </button>

                        {activeDropdown === inquiry._id && (
                          <div className="absolute z-20 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200">
                            {[
                              "Pending",
                              "Discard",
                              "Evaluating",
                              "Confirm",
                              "Hide",
                            ].map((status) => (
                              <button
                                key={status}
                                onClick={() => {
                                  handleStatusChange(
                                    inquiry.inquiry._id,
                                    status
                                  ); // Call status change handler
                                  setActiveDropdown(null); // Close dropdown after selection
                                }}
                                className="w-full text-left px-4 py-2 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                              >
                                <StatusBadge status={status} />
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <button
                        onClick={() => handleMarkSold(inquiry.inquiry._id)} // Call the mark sold handler
                        className="flex items-center gap-2 px-4 py-2 bg-green-100 text-green-700 rounded-lg hover:bg-green-100 transition"
                      >
                        <CheckCircle size={16} />
                        Mark as Sold
                      </button>

                      <button
                        onClick={() => handleDelete(inquiry.inquiry._id)} // Call delete handler
                        className="flex items-center gap-2 px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition"
                      >
                        <Trash2 size={16} />
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {inquiries.length === 0 && (
              <div className="text-center py-12">
                <Car className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900">
                  No inquiries found
                </h3>
                <p className="text-gray-500">
                  New inquiries will appear here when customers submit them.
                </p>
              </div>
            )}
          </div>
        )}
        {sellRequest && (
          <div className="space-y-6">
            {hiddenVehicles.map((vehicle) => (
              <div
                key={vehicle._id}
                className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
              >
                <div className="p-6">
                  {/* Vehicle Header */}
                  <div
                    onClick={() => navigate("/product/" + vehicle._id)} // Navigate to the product page
                    className="flex items-center cursor-pointer justify-between mb-6"
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={vehicle.thumbnail} // Assuming 'thumbnail' is the image property
                        alt="Vehicle"
                        className="w-20 h-20 object-cover rounded-lg"
                      />
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900">
                          {vehicle.name}
                        </h2>
                        {/* Location */}
                        {vehicle?.location?.[1] && vehicle?.location?.[0] && (
                          <div className="py-2 text-gray-600">
                            <MapPin className="inline-block w-5 h-5 mr-1" />
                            <span>
                              {vehicle?.location?.[1]}, {vehicle?.location?.[0]}
                            </span>
                          </div>
                        )}
                        {/* Inquiry received date */}
                        <p className="text-sm text-gray-500">
                          Inquiry received on{" "}
                          {new Date(vehicle.created_at).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <StatusBadge
                      status={vehicle.to_show ? "Visible" : "Hidden"}
                    />
                  </div>

                  {/* Vehicle Details */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold text-gray-900">
                        Vehicle Details
                      </h3>
                      <div className="space-y-1">
                        <InfoRow
                          icon={User}
                          label="Owner"
                          value={vehicle.user_info?.name}
                        />
                        <InfoRow
                          icon={Phone}
                          label="Phone"
                          value={vehicle.user_info?.phone}
                        />
                        <InfoRow
                          icon={Mail}
                          label="Email"
                          value={vehicle.user_info?.email}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Address"
                          value={vehicle.user_info?.address}
                        />
                        <InfoRow
                          icon={DollarSign}
                          label="Cost"
                          value={`Rs. ${vehicle?.cost}`}
                        />
                      </div>
                    </div>

                    {/* Additional Vehicle Information */}
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold text-gray-900">
                        Additional Info
                      </h3>
                      <div className="space-y-1">
                        <InfoRow
                          icon={MapPin}
                          label="Category"
                          value={vehicle.category === "Light Trucks" ? "Mini Trucks" : vehicle.category}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Brand"
                          value={vehicle.brand}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Manufacture Year"
                          value={vehicle.manufacture_year}
                        />
                        <InfoRow
                          icon={MapPin}
                          label="Fuel Type"
                          value={vehicle.fuel_type || "N/A"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Documents */}
                  <div className="mt-6 flex items-center justify-between">
                    <button
                      onClick={() => handleMakePublic(vehicle._id)} // Function to update to_show to true
                      className="flex items-center gap-2 px-4 py-2 bg-green-50 text-green-700 rounded-lg hover:bg-green-100 transition"
                    >
                      <CheckCircle size={16} />
                      Make Public
                    </button>
                    <div className=" border-t border-gray-100">
                      <div className="flex items-center gap-4">
                        {vehicle.documents?.rc?.filename && (
                          <div
                            onClick={() => {
                              // Trigger the download by setting window.location.href to the RC file URL
                              const rcUrl = vehicle.documents.rc.filename;
                              const link = document.createElement("a");
                              link.href = rcUrl;
                              link.download = rcUrl.split("/").pop(); // Get the file name from the URL (optional)
                              link.click(); // Simulate a click to trigger the download
                            }}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition cursor-pointer"
                          >
                            <FileCheck size={16} />
                            RC Available
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Action Buttons */}

                    {/* Delete Button */}
                    <button
                      onClick={() => handleProductDelete(vehicle._id)} // Function to delete the vehicle
                      className="flex items-center gap-2 px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition"
                    >
                      <Trash2 size={16} />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {/* Empty State */}
            {hiddenVehicles.length === 0 && (
              <div className="text-center py-12">
                <Car className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900">
                  No vehicles found
                </h3>
                <p className="text-gray-500">
                  Hidden vehicles will appear here when they are available.
                </p>
              </div>
            )}
          </div>
        )}
        {visibleVehicle && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {visibleVehiclesData
              .filter((vehicle) => vehicle.to_show === true)
              .map((vehicle) => (
                <div
                  key={vehicle._id.$oid}
                  className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden p-6"
                >
                  {/* Vehicle Header */}
                  <div
                    onClick={() => navigate("/product/" + vehicle._id.$oid)} // Navigate to the product page
                    className="flex items-center cursor-pointer justify-between mb-4"
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={vehicle.thumbnail} // Assuming 'thumbnail' is the image property
                        alt="Vehicle"
                        className="w-28 h-28 object-cover rounded-lg"
                      />
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900">
                          {vehicle.brand} {vehicle.model} {" "}
                          {vehicle.manufacture_year}
                        </h2>
                        <p className="text-sm text-gray-500">
                          {vehicle?.registration?.registration_city},{" "}
                          {vehicle?.registration?.registration_state}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Vehicle Details (Grid Layout for Information) */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    {/* Owner Info */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <User className="w-5 h-5 mr-2" />
                      <span>{vehicle.name || "N/A"}</span>
                    </div>

                    {/* Cost */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <DollarSign className="w-5 h-5 mr-2" />
                      <span>Cost: Rs. {vehicle.cost || "N/A"}</span>
                    </div>

                    {/* Manufacture Year */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>
                        Manufacture Year: {vehicle.manufacture_year || "N/A"}
                      </span>
                    </div>

                    {/* Engine Info */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>Engine Type: {vehicle.engine.type || "N/A"}</span>
                    </div>
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>
                        Displacement: {vehicle.engine.displacement || "N/A"}
                      </span>
                    </div>
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>Power: {vehicle.engine.power || "N/A"}</span>
                    </div>

                    {/* Wheel Health & Capacity */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>
                        Wheel Health: {vehicle.registration.wheel_health}%
                      </span>
                    </div>
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>
                        Wheel Count: {vehicle.registration.wheel_count || "N/A"}
                      </span>
                    </div>

                    {/* Additional Info */}
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>Category: {vehicle.category === "Light Trucks" ? "Mini Trucks" : vehicle.category}</span>                    </div>
                    <div className="flex items-center text-gray-600 text-sm">
                      <MapPin className="w-5 h-5 mr-2" />
                      <span>Brand: {vehicle.brand || "N/A"}</span>
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-between items-center mt-4">
                    {/* Delete Button */}
                    <button
                      onClick={() => handleProductDelete(vehicle._id.$oid)} // Handle deletion
                      className="flex items-center gap-2 px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition"
                    >
                      <Trash2 size={16} />
                      Delete
                    </button>

                    {/* Visibility Status */}
                    <StatusBadge
                      status={vehicle.to_show ? "Visible" : "Hidden"}
                    />
                  </div>
                </div>
              ))}

            {/* Empty State */}
            {visibleVehiclesData.filter((vehicle) => vehicle.to_show === true)
              .length === 0 && (
              <div className="text-center py-12">
                <Car className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900">
                  No Vehicles found
                </h3>
                <p className="text-gray-500">
                  Visible vehicles will appear here.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
