import React, { useState, useEffect } from "react";
import img1 from "../assets/heroSection/img1.jpg";
import img2 from "../assets/heroSection/img2.jpg";
import img3 from "../assets/heroSection/img3.jpg";
import img4 from "../assets/heroSection/img4.jpg";
import img5 from "../assets/heroSection/img5.jpg";
import img6 from "../assets/heroSection/img6.jpg";
import img7 from "../assets/heroSection/img7.jpg";
import img8 from "../assets/heroSection/img8.jpg";

// Image array
const images = [
  {
    src: img1,
    title: "High-Quality Truck Parts",
    description:
      "Explore our extensive range of durable truck parts for all makes and models.",
  },
  {
    src: img2,
    title: "Reliable Trucks for Sale",
    description:
      "Check out our selection of reliable used trucks, thoroughly inspected and ready to go.",
  },
  {
    src: img3,
    title: "Affordable Accessories",
    description:
      "Find affordable accessories to enhance your truck’s performance and style.",
  },
  {
    src: img4,
    title: "Expert Support & Service",
    description:
      "Our team is here to provide expert support and advice for all your truck needs.",
  },
  {
    src: img5,
    title: "High-Quality Truck Parts",
    description:
      "Explore our extensive range of durable truck parts for all makes and models.",
  },
  {
    src: img6,
    title: "Reliable Trucks for Sale",
    description:
      "Check out our selection of reliable used trucks, thoroughly inspected and ready to go.",
  },
  {
    src: img7,
    title: "Affordable Trucks",
    description:
      "Find affordable Trucks to enhance your business performance.",
  },
  {
    src: img8,
    title: "Expert Support & Service",
    description:
      "Our team is here to provide expert support and advice for all your truck needs.",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full mx-auto mt-4 ">
      {/* Carousel Wrapper */}
      <div className="relative overflow-hidden rounded-lg shadow-lg">
        <div
          className="flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div key={index} className="w-full flex-shrink-0 relative">
              <img
                src={image.src}
                alt={`Slide ${index + 1}`}
                className="w-full h-[600px] object-cover rounded-lg"
              />
              {/* Overlay with Text */}
              <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 flex flex-col justify-center items-center p-4">
                <h2 className="text-xl font-bold text-white">{image.title}</h2>
                <p className="text-white mt-2 text-center px-4">
                  {image.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Prev and Next buttons */}
        <button
          onClick={prevSlide}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-600 transition-colors shadow-md"
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-600 transition-colors shadow-md"
        >
          &#10095;
        </button>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-4 space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-gray-800" : "bg-gray-300"
            } hover:bg-gray-500 transition-colors`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
