import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { X, Upload, Trash2, ArrowDown, ArrowUp } from "lucide-react";
import { File, Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultipleImageUpload from "./MultipleImageUpload";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const options = [
  { value: "Full Body", label: "Full Body" },
  { value: "Half Body", label: "Half Body" },
  { value: "Container", label: "Container" },
  { value: "Open Body", label: "Open Body" },
  { value: "Closed Body", label: "Closed Body" },
  { value: "Box Body", label: "Box Body" },
  { value: "Flatbed", label: "Flatbed" },
  { value: "Refrigerated", label: "Refrigerated" },
  { value: "Tanker", label: "Tanker" },
  { value: "Tipper", label: "Tipper" },
  { value: "Lorry", label: "Lorry" },
  { value: "Dry Van", label: "Dry Van" },
  { value: "Livestock Carrier", label: "Livestock Carrier" },
  { value: "Curtain Side", label: "Curtain Side" },
  { value: "Lowboy", label: "Lowboy" },
  { value: "Car Carrier", label: "Car Carrier" },
  { value: "Dump Truck", label: "Dump Truck" },
  { value: "Cattle Carrier", label: "Cattle Carrier" },
  { value: "Flatbed Trailer", label: "Flatbed Trailer" },
  { value: "Grain Hauler", label: "Grain Hauler" },
  { value: "Bulk Hauler", label: "Bulk Hauler" },
  { value: "Refrigerated Trailer", label: "Refrigerated Trailer" },
  { value: "Auto Transporter", label: "Auto Transporter" },
  { value: "Box Trailer", label: "Box Trailer" },
  { value: "Platform Body", label: "Platform Body" },
  { value: "Tilt Body", label: "Tilt Body" },
];

const engineTechOptions = [
  { value: "", label: "Select Engine Tech" },
  { value: "BS1", label: "BS1" },
  { value: "BS2", label: "BS2" },
  { value: "BS3", label: "BS3" },
  { value: "BS4", label: "BS4" },
  { value: "BS5", label: "BS5" },
  { value: "BS6", label: "BS6" },
  { value: "BS7", label: "BS7" },
  { value: "BS8", label: "BS8" },
  { value: "BS9", label: "BS9" },
];

export default function AddProduct() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const [imageUrl, setImageUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [aadhaarUrl, setAadhaarUrl] = useState("");
  const [isAadhaarUploading, setIsAadhaarUploading] = useState(false);
  const [rcUrl, setRcUrl] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesRegister, setCitiesRegister] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedRegisterState, setSelectedRegisterState] = useState("");
  const [selectedRegisterCity, setSelectedRegisterCity] = useState("");
  const navigate = useNavigate();
  const [categories] = useState(["Light Trucks", "Truck", "Tippers", "Bus"]); // Example categories
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [newBrand, setNewBrand] = useState("");
  const [newModel, setNewModel] = useState("");
  const [vehicleImages, setVehicleImages] = useState([]);

  const [showOwnerDropdown, setShowOwnerDropdown] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    brand: "",
    model: "",
    manufacture_year: "",
    area: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    wheel_count: "",
    payload_capacity: "",
    fuel_type: "",
    transmission: "",
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 500, // Set the height to 50px
      overflowY: "auto", // Add scrollbar
    }),
  };

  const onSubmit = async (data) => {
    if (vehicleImages.length > 0) {
      data.thumbnail = vehicleImages[0]; // First image as thumbnail
      data.images = vehicleImages; // All images
      data.documents = {
        rc: rcUrl,
      };
      data.brand = selectedBrand;
      data.model = selectedModel;
      if (data.category == "Truck") {
        data.category = "Medium Trucks";
      }
      try {
        await axios.post("/api/vehicles", data);
        reset();
        setVehicleImages([]);
        setRcUrl("");
        setAadhaarUrl("");
        alert("Vehicle added successfully!");
        navigate("/");
      } catch (error) {
        console.error("Error adding vehicle:", error);
        alert("Failed to add vehicle.");
      }
    } else {
      alert("Please upload at least one vehicle image.");
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          "/api/locations"
        );
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states", error);
      }
    };
    fetchStates();
  }, []);

  // Fetch cities based on selected state
  const fetchCities = async (state, condition) => {
    try {
      const response = await axios.get(
        `/api/locations?state=${state}`
      );
      if (condition === "profile") {
        setCities(response.data.city_list);
      } else {
        setCitiesRegister(response.data.city_list);
      }
    } catch (error) {
      console.error("Error fetching cities", error);
      setCities([]);
    }
  };

  // Handle state change in the dropdown
  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    fetchCities(state, "profile");
    setValue("profile.State", state); // Update form state
  };

  // Handle city change in the dropdown
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("profile.City", event.target.value); // Update form city state
  };

  const handleRegisterStateChange = (event) => {
    const state = event.target.value;
    setSelectedRegisterState(state); // Correct state setter
    fetchCities(state, "register"); // Fetch cities for selected state
    setValue("registration_state", state); // Update form state
  };

  const handleRegisterCityChange = (event) => {
    // const city = event.target.value;
    setSelectedRegisterCity(event.target.value); // Correct city setter
    setValue("registration_city", event.target.value); // Update form city state
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Create preview URL
    const preview = URL.createObjectURL(file);
    setPreviewUrl(preview);
    setIsUploading(true);

    // Check file size (50 KB)
    if (file.size) {
      const options = {
        maxSizeMB: 0.05,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const formData = new FormData();
        formData.append("file", compressedFile);

        const response = await axios.post(
          "/api/upload",
          formData
        );
        setImageUrl(response.data.filename);
      } catch (error) {
        console.error("Image upload failed:", error);
        alert("Image upload failed.");
        removeImage();
      }
    } else {
      alert("File size must be less than 50 KB.");
      removeImage();
    }
    setIsUploading(false);
  };

  const handleAadhaarUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    setIsAadhaarUploading(true);

    try {
      let compressedFile;

      if (fileType.includes("image")) {
        // Compress image to 100 KB
        const options = {
          maxSizeMB: 0.1, // 100 KB
          useWebWorker: true,
        };
        compressedFile = await imageCompression(file, options);
      } else {
        alert("Only images or PDFs are allowed.");
        // removeAadhaar();
        return;
      }

      const formData = new FormData();
      formData.append("file", compressedFile);

      const response = await axios.post(
        "./api/upload",
        formData
      );
      setAadhaarUrl(response.data.filename);
    } catch (error) {
      console.error("Aadhaar upload failed:", error);
      alert("Aadhaar upload failed.");
      // removeAadhaar();
    }

    setIsAadhaarUploading(false);
  };

  const fetchBrands = async (category) => {
    try {
      const response = await axios.get(
        `/api/brands?category=${category}`
      );
      setBrands(response.data);
    } catch (error) {
      console.error("Error fetching brands", error);
      setBrands([]);
    }
  };

  // Fetch models based on selected category and brand
  const fetchModels = async (category, brand) => {
    try {
      const response = await axios.get(
        `/api/models?category=${category}&brand=${brand}`
      );
      setModels(response.data);
    } catch (error) {
      console.error("Error fetching models", error);
      setModels([]);
    }
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    fetchBrands(category);
    setValue("vehicle.category", category);
    setValue("vehicle.brand", "");
    setValue("vehicle.model", "");
    setSelectedBrand("");
    setSelectedModel("");
    setNewBrand("");
    setNewModel("");
  };

  // Handle brand change
  const handleBrandChange = (event) => {
    const brand = event.target.value;
    setSelectedBrand(brand);
    fetchModels(selectedCategory, brand);
    setValue("vehicle.brand", brand);
    setValue("vehicle.model", "");
    setSelectedModel("");
    setNewModel(""); // Reset new model input
  };

  // Handle model change
  const handleModelChange = async (event) => {
    const model = event.target.value;
    setSelectedModel(model);
    await fetchPrototypeData();
    setValue("vehicle.model", model);
  };

  // Add new brand
  const handleAddNewBrand = () => {
    if (!newBrand.trim()) return;

    try {
      setBrands((prev) => [...prev, newBrand]);
      setSelectedBrand(newBrand);
      setNewBrand(""); // Reset the input field
    } catch (error) {
      console.error("Error adding new brand:", error);
    }
  };

  // Add new model
  const handleAddNewModel = async () => {
    if (!newModel.trim()) return;

    try {
      setModels((prev) => [...prev, newModel]);
      setSelectedModel(newModel);
      setNewModel(""); // Reset the input field
    } catch (error) {
      console.error("Error adding new model:", error);
    }
  };

  const fetchPrototypeData = async () => {
    try {
      const response = await axios.get(
        `/api/vehicles?category=${selectedCategory}&brand=${selectedBrand}&model=${selectedModel}`
      );

      // Check if the response data contains any vehicles
      if (Array.isArray(response.data) && response.data.length > 0) {
        const vehicle = response.data[0]; // Assuming we're dealing with the first vehicle object

        // Update both form state and react-hook-form values
        setFormData({
          name: vehicle.name || "",
          category: vehicle.category || "",
          brand: vehicle.brand || "",
          model: vehicle.model || "",
          manufacture_year: vehicle.manufacture_year || "",
          area: vehicle.area || "",
          height: vehicle.height || "",
          width: vehicle.width || "",
          length: vehicle.length || "",
          weight: vehicle.weight || "",
          wheel_count: vehicle.wheel_count || "",
          payload_capacity: vehicle.payload_capacity || "",
          fuel_type: vehicle.fuel_type || "",
          transmission: vehicle.transmission || "",
          engine_displacement: vehicle.engine?.displacement || "",
          engine_power: vehicle.engine?.power || "",
          engine_torque: vehicle.engine?.torque || "",
          engine_type: vehicle.engine?.type || "",
        });

        // Use setValue to update fields in react-hook-form
        setValue("name", vehicle.name || "");
        setValue("category", vehicle.category || "");
        setValue("brand", vehicle.brand || "");
        setValue("model", vehicle.model || "");
        setValue("manufacture_year", vehicle.manufacture_year || "");
        setValue("area", vehicle.area || "");
        setValue("height", vehicle.height || "");
        setValue("width", vehicle.width || "");
        setValue("length", vehicle.length || "");
        setValue("weight", vehicle.weight || "");
        setValue("wheel_count", vehicle.wheel_count || "");
        setValue("payload_capacity", vehicle.payload_capacity || "");
        setValue("fuel_type", vehicle.fuel_type || "");
        setValue("transmission", vehicle.transmission || "");
        setValue("engine_displacement", vehicle.engine?.displacement || "");
        setValue("engine_power", vehicle.engine?.power || "");
        setValue("engine_torque", vehicle.engine?.torque || "");
        setValue("engine_type", vehicle.engine?.type || "");
      }
    } catch (error) {
      console.error("Error fetching prototype data:", error);
    }
  };

  const removeImage = () => {
    setImageUrl("");
    setPreviewUrl("");
    // Reset file input
    const fileInput = document.getElementById("image");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="px-6 py-8 sm:p-10">
            <div className="text-center mb-10">
              <h1 className="text-3xl font-bold text-gray-900">
                Add New Vehicle
              </h1>
              <p className="mt-2 text-gray-600">
                {t("Fill in the details below to list your vehicle")}
              </p>
            </div>

            <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
              {/* Image Upload Section */}
              {/* <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700">
                  Vehicle Image
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed border-gray-300 rounded-lg">
                  <div className="space-y-2 text-center">
                    {previewUrl ? (
                      <div className="relative inline-block">
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="max-h-64 rounded-lg object-contain"
                        />
                        <button
                          type="button"
                          onClick={removeImage}
                          className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="image"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="image"
                              name="image"
                              type="file"
                              accept="image/*"
                              className="sr-only"
                              onChange={handleImageUpload}
                              disabled={isUploading}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 50KB
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <MultipleImageUpload
                  onImagesChange={setVehicleImages}
                  maxImages={6}
                />
              </div>

              {/* Profile Section */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-6">
                  Profile Information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      {...register("profile.name", { required: true })}
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Your Name"
                    />
                    {errors.profile?.name && (
                      <p className="mt-1 text-sm text-red-600">
                        Name is required
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      {...register("profile.email", { required: true })}
                      type="email"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="your.email@example.com"
                    />
                    {errors.profile?.email && (
                      <p className="mt-1 text-sm text-red-600">
                        Email is required
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <input
                      {...register("profile.phone", { required: true })}
                      type="tel"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Your Phone Number"
                    />
                    {errors.profile?.phone && (
                      <p className="mt-1 text-sm text-red-600">
                        Phone is required
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Address
                    </label>
                    <input
                      {...register("profile.address", { required: true })}
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Your Address"
                    />
                    {errors.profile?.address && (
                      <p className="mt-1 text-sm text-red-600">
                        Address is required
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <select
                      {...register("profile.State", {
                        required: "State is required",
                      })}
                      value={selectedState}
                      onChange={handleStateChange}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    {errors.profile?.State && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.profile?.State.message}
                      </p>
                    )}
                  </div>

                  {/* City Dropdown */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      City
                    </label>
                    <select
                      {...register("profile.City", {
                        required: "City is required",
                      })}
                      value={selectedCity}
                      onChange={handleCityChange}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      disabled={!selectedState}
                    >
                      <option value="">Select City</option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    {errors.profile?.City && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.profile?.City.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Vehicle Details Section */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-6">
                  Vehicle Details
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-2">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Vehicle Name
                    </label>
                    <input
                      {...register("name", {
                        required: "Vehicle name is required",
                      })}
                      type="text"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Vehicle Name"
                    />
                    {errors.name && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Category
                    </label>
                    <select
                      {...register("category", {
                        required: "Category is required",
                      })}
                      value={selectedCategory} // Controlled component for selected category
                      onChange={(e) => {
                        setFormData({ ...formData, category: e.target.value }); // Update the formData state
                        handleCategoryChange(e); // Call the handleCategoryChange function
                      }}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category === "Light Trucks"
                            ? "Mini Trucks"
                            : category}
                        </option>
                      ))}
                    </select>

                    {errors.category && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.category.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="justify-between flex w-full gap-4">
                  {selectedCategory === "Bus" && (
                    <div className="w-full max-h-10">
                      <label className="block text-sm font-medium text-gray-700">
                        Bus Type
                      </label>
                      <Controller
                        name="busType"
                        control={control}
                        // rules={{ required: "Bus Type is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              { value: "School Bus", label: "School Bus" },
                              { value: "Staff Bus", label: "Staff Bus" },
                              { value: "Route Bus", label: "Route Bus" },
                              { value: "Shuttle Bus", label: "Shuttle Bus" },
                              { value: "Tourist Bus", label: "Tourist Bus" },
                              { value: "City Bus", label: "City Bus" },
                              {
                                value: "Intercity Bus",
                                label: "Intercity Bus",
                              },
                              {
                                value: "Double Decker Bus",
                                label: "Double Decker Bus",
                              },
                              { value: "Electric Bus", label: "Electric Bus" },
                              { value: "Luxury Bus", label: "Luxury Bus" },
                              { value: "Minibus", label: "Minibus" },
                              { value: "Coach Bus", label: "Coach Bus" },
                              { value: "Sleeper Bus", label: "Sleeper Bus" },
                              {
                                value: "Articulated Bus",
                                label: "Articulated Bus",
                              },
                              { value: "Party Bus", label: "Party Bus" },
                              {
                                value: "Airport Transfer Bus",
                                label: "Airport Transfer Bus",
                              },
                              { value: "Vintage Bus", label: "Vintage Bus" },
                            ]}
                            styles={customStyles}
                            placeholder="Select Bus Type"
                          />
                        )}
                      />
                      {errors.busType && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.busType.message}
                        </p>
                      )}
                    </div>
                  )}

                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700 ">
                      Brand
                    </label>
                    <select
                      {...register("brand", {
                        required: "Brand is required",
                      })}
                      value={selectedBrand}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevData) => ({
                          ...prevData,
                          brand: value,
                        })); // Update the formData state
                        handleBrandChange(e); // Ensure handleBrandChange is called
                      }}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="">Select Brand</option>
                      <option value="add-new">Add New</option>
                      {brands.map((brand) => (
                        <option key={brand} value={brand}>
                          {brand}
                        </option>
                      ))}
                    </select>

                    {/* Show input for adding new brand */}
                    {selectedBrand === "add-new" && (
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Enter new brand"
                          value={newBrand}
                          onChange={(e) => setNewBrand(e.target.value)} // Update the newBrand state
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                        <button
                          type="button"
                          onClick={handleAddNewBrand} // Ensure the new brand is added when clicked
                          className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                        >
                          Add Brand
                        </button>
                      </div>
                    )}

                    {errors.brand && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.brand.message}
                      </p>
                    )}
                  </div>

                  {/* Model Dropdown */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Model
                    </label>
                    <select
                      {...register("model", {
                        required: "Model is required",
                      })}
                      value={selectedModel}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevData) => ({
                          ...prevData,
                          model: value,
                        })); // Update the formData state
                        handleModelChange(e); // Ensure handleModelChange is called
                      }}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      disabled={!selectedBrand} // Disable model dropdown until a brand is selected
                    >
                      <option value="">Select Model</option>
                      <option value="add-new">Add New</option>
                      {models.map((model, index) => (
                        <option key={index} value={model}>
                          {model}
                        </option>
                      ))}
                    </select>

                    {/* Show input for adding new model */}
                    {selectedModel === "add-new" && (
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Enter new model"
                          value={newModel}
                          onChange={(e) => setNewModel(e.target.value)} // Update the newModel state
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                        <button
                          type="button"
                          onClick={handleAddNewModel} // Ensure the new model is added when clicked
                          className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                        >
                          Add Model
                        </button>
                      </div>
                    )}

                    {errors.model && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.model.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div>* Manufacture Year */}
                <div className="flex justify-evenly gap-4 py-2">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Manufacture Year
                    </label>
                    <input
                      {...register("manufacture_year", {
                        required: "Manufacture Year is required",
                      })}
                      value={formData.manufacture_year}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          manufacture_year: e.target.value,
                        })
                      }
                      type="number"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="2024"
                    />
                    {errors.manufacture_year && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.manufacture_year.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Owners Till Date
                    </label>
                    <select
                      {...register("onwers_till_date", {
                        required: "Owners Till Date option is required",
                      })}
                      // defaultValue={false}
                      onChange={(e) => {
                        // Handling the change to conditionally render the owners dropdown

                        const selectedValue = e.target.value;
                        if (selectedValue === "yes") {
                          setShowOwnerDropdown(true);
                        } else {
                          setShowOwnerDropdown(false);
                        }
                      }}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                    {errors.onwers_till_date && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.onwers_till_date.message}
                      </p>
                    )}
                  </div>

                  {/* Conditionally Render the Owners Dropdown if "Yes" is Selected */}
                  {showOwnerDropdown && (
                    <div className="w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Select Owner Number
                      </label>
                      <select
                        {...register("owners_number")}
                        className="p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        {/* Generate owner options from 1st to 10th */}
                        {[...Array(10).keys()].map((index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}{" "}
                            {index === 0
                              ? "st"
                              : index === 1
                              ? "nd"
                              : index === 2
                              ? "rd"
                              : "th"}{" "}
                            Owner
                          </option>
                        ))}
                      </select>
                      {errors.owners_number && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.owners_number.message}
                        </p>
                      )}
                    </div>
                  )}

                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Hypothecation
                    </label>
                    <select
                      {...register("hypothecation", {
                        required: "Hypotication option is required",
                      })}
                      // onChange={(e) =>
                      //   setFormData({
                      //     ...formData,
                      //     hypothecation: e.target.value,
                      //   })
                      // }
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    {errors.hypothecation && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.hypothecation.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="pt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Registration Details
                  </label>
                  <div className="flex py-2 gap-4 justify-evenly">
                    <div className="w-1/2">
                      <label className="block text-sm font-medium text-gray-700">
                        Registration State
                      </label>
                      <select
                        {...register("registration_state", {
                          required: "State is required",
                        })}
                        value={selectedRegisterState}
                        onChange={handleRegisterStateChange}
                        className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      {errors.registration_state && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.registration_state.message}
                        </p>
                      )}
                    </div>
                    <div className="w-1/2">
                      <label className="block text-sm font-medium text-gray-700">
                        Registration City
                      </label>
                      <select
                        {...register("registration_city", {
                          required: "City is required",
                        })}
                        value={selectedRegisterCity}
                        onChange={handleRegisterCityChange}
                        className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="">Select City</option>
                        {citiesRegister.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                      {errors.registration_city && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.registration_city.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-evenly gap-3 py-2">
                  {/* Insurance Validity Date Picker */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Insurance Validity
                    </label>
                    <Controller
                      name="insurance_validity"
                      control={control}
                      rules={{ required: "Insurance Validity is required" }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <DatePicker
                          {...rest}
                          selected={value}
                          onChange={onChange}
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          dateFormat="MM-yyyy" // Only show month and year
                          showMonthYearPicker // Restrict to selecting month and year
                          placeholderText={
                            value
                              ? `Selected: ${value.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "2-digit",
                                })}`
                              : "Select insurance validity"
                          }
                        />
                      )}
                    />
                    {errors.insurance_validity && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.insurance_validity.message}
                      </p>
                    )}
                  </div>

                  {/* Permit Validity Date Picker */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Permit Validity
                    </label>
                    <Controller
                      name="permit_validity"
                      control={control}
                      rules={{ required: "Permit Validity is required" }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <DatePicker
                          {...rest}
                          selected={value}
                          onChange={onChange}
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          dateFormat="MM-yyyy" // Only show month and year
                          showMonthYearPicker // Restrict to selecting month and year
                          placeholderText={
                            value
                              ? `Selected: ${value.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "2-digit",
                                })}`
                              : "Select permit validity"
                          }
                        />
                      )}
                    />
                    {errors.permit_validity && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.permit_validity.message}
                      </p>
                    )}
                  </div>

                  {/* Tax Validity Date Picker */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Tax Validity
                    </label>
                    <Controller
                      name="tax_validity"
                      control={control}
                      rules={{ required: "Tax Validity is required" }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <DatePicker
                          {...rest}
                          selected={value}
                          onChange={onChange}
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          dateFormat="MM-yyyy" // Only show month and year
                          showMonthYearPicker // Restrict to selecting month and year
                          placeholderText={
                            value
                              ? `Selected: ${value.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "2-digit",
                                })}`
                              : "Select tax validity"
                          }
                        />
                      )}
                    />
                    {errors.tax_validity && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.tax_validity.message}
                      </p>
                    )}
                  </div>

                  {/* Fitness Validity Date Picker */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Fitness Validity
                    </label>
                    <Controller
                      name="fitness_validity"
                      control={control}
                      rules={{ required: "Fitness Validity is required" }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <DatePicker
                          {...rest}
                          selected={value}
                          onChange={onChange}
                          className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          dateFormat="MM-yyyy" // Only show month and year
                          showMonthYearPicker // Restrict to selecting month and year
                          placeholderText={
                            value
                              ? `Selected: ${value.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "2-digit",
                                })}`
                              : "Select fitness validity"
                          }
                        />
                      )}
                    />
                    {errors.fitness_validity && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.fitness_validity.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium">
                      RC Available
                    </label>
                    <Controller
                      name="rcAvailable"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                        >
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      )}
                    />
                    {errors.rcAvailable && (
                      <p className="text-red-500 text-sm">
                        {errors.rcAvailable.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium">
                      Aadhar Available
                    </label>
                    <Controller
                      name="aadhaarAvailable"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                        >
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      )}
                    />
                    {errors.aadhaarAvailable && (
                      <p className="text-red-500 text-sm">
                        {errors.aadhaarAvailable.message}
                      </p>
                    )}
                  </div>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                  {/* Weight */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Vehicle Type
                    </label>
                    <select
                      {...register("vehicle_condition")}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="new">New</option>
                      <option value="old">Used</option>
                    </select>
                    {errors.vehicle_condition && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.vehicle_condition.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      GVW (kg)
                    </label>
                    <input
                      {...register("weight", {
                        required: "GVW (kg) is required",
                      })}
                      type="text"
                      value={formData.weight}
                      onChange={(e) =>
                        setFormData({ ...formData, weight: e.target.value })
                      }
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Weight"
                    />
                    {errors.weight && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.weight.message}
                      </p>
                    )}
                  </div>

                  {/* Price */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Price
                    </label>
                    <input
                      {...register("cost", { required: "Price is required" })}
                      type="number"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Selling Price"
                    />
                    {errors.cost && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.cost.message}
                      </p>
                    )}
                  </div>

                  {/* Wheel Count */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Number of Tyres
                    </label>
                    <input
                      {...register("wheel_count", {
                        required: "Number of Tyres is required",
                      })}
                      value={formData.wheel_count}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          wheel_count: e.target.value,
                        })
                      }
                      type="number"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Number of Tyres"
                    />
                    {errors.wheel_count && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.wheel_count.message}
                      </p>
                    )}
                  </div>

                  {/* Payload Capacity */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Payload Capacity (Tons)
                    </label>
                    <input
                      {...register("payload_capacity", {
                        required: "Payload Capacity is required",
                      })}
                      value={formData.payload_capacity}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          payload_capacity: e.target.value,
                        })
                      }
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Payload Capacity"
                    />
                    {errors.payload_capacity && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.payload_capacity.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full max-h-10">
                    <label className="block text-sm font-medium text-gray-700">
                      Body Type
                    </label>
                    <Controller
                      name="body_type"
                      control={control}
                      rules={{ required: "Body Type is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={options}
                          styles={customStyles}
                          placeholder="Select Body Type"
                        />
                      )}
                    />
                    {errors.body_type && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.body_type.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Area */}
                <div className="flex justify-evenly gap-4 py-2">
                  {/* Seat Count Input */}
                  <div className="py-2 w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Seat Count
                    </label>
                    <input
                      {...register("seat_count", {
                        required: "Seat Count is required",
                        valueAsNumber: true, // Ensure the value is treated as a number
                        min: {
                          value: 1,
                          message: "Seat Count must be at least 1",
                        },
                        max: {
                          value: 100,
                          message: "Seat Count cannot exceed 100",
                        },
                      })}
                      value={formData.seat_count}
                      onChange={(e) =>
                        setFormData({ ...formData, seat_count: e.target.value })
                      }
                      type="number"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Seat Count"
                    />
                    {errors.seat_count && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.seat_count.message}
                      </p>
                    )}
                  </div>

                  {/* Wheel Health Percentage Input */}
                  <div className="py-2 w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Tyre condition (%)
                    </label>
                    <input
                      {...register("wheel_health", {
                        required: "Tire condition is required",
                        valueAsNumber: true, // Ensure the value is treated as a number
                        min: {
                          value: 0,
                          message: "Tire condition cannot be less than 0%",
                        },
                        max: {
                          value: 100,
                          message: "Tire condition cannot exceed 100%",
                        },
                      })}
                      type="number"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Tire condition"
                    />
                    {errors.wheel_health && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.wheel_health.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Dimensions */}
                <div className="mt-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Dimensions (In Feet)
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <input
                        {...register("height", {
                          required: "Height is required",
                        })}
                        value={formData.height}
                        onChange={(e) =>
                          setFormData({ ...formData, height: e.target.value })
                        }
                        type="text"
                        placeholder="Height"
                        className="block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {errors.height && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.height.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        {...register("width", {
                          required: "Width is required",
                        })}
                        value={formData.width}
                        onChange={(e) =>
                          setFormData({ ...formData, width: e.target.value })
                        }
                        type="text"
                        placeholder="Width"
                        className="block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {errors.width && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.width.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        {...register("length", {
                          required: "Length is required",
                        })}
                        value={formData.length}
                        onChange={(e) =>
                          setFormData({ ...formData, length: e.target.value })
                        }
                        type="text"
                        placeholder="Length"
                        className="block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {errors.length && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors.length.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Engine Details */}
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 gap-6">
                  {/* Engine Type */}
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Engine Tech Type
                    </label>
                    <select
                      {...register("engine_tech_type", {
                        required: "Engine Tech Type is required",
                      })}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      {engineTechOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    {/* Error handling */}
                    {errors.engine_tech_type && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.engine_tech_type.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Engine Type
                    </label>
                    <select
                      {...register("engine_type", {
                        required: "Engine Type is required",
                      })}
                      value={formData.engine_type}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          engine_type: e.target.value,
                        })
                      }
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 overflow-auto"
                    >
                      <option value="" disabled>
                        Select Engine Type
                      </option>
                      <option value="Petrol">Petrol</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Electric">Electric</option>
                      <option value="CNG">CNG</option>
                    </select>
                    {errors.engine_type && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.engine_type.message}
                      </p>
                    )}
                  </div>

                  {/* Engine Displacement */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Engine Displacement (cc)
                    </label>
                    <input
                      {...register("engine_displacement", {
                        required: "Engine Displacement is required",
                      })}
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Engine Displacement"
                      value={formData.engine_displacement}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          engine_displacement: e.target.value,
                        })
                      }
                    />
                    {errors.engine_displacement && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.engine_displacement.message}
                      </p>
                    )}
                  </div>

                  {/* Engine Power */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Engine Power (hp)
                    </label>
                    <input
                      {...register("engine_power", {
                        required: "Engine Power is required",
                      })}
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Engine Power"
                      value={formData.engine_power}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          engine_power: e.target.value,
                        })
                      }
                    />
                    {errors.engine_power && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.engine_power.message}
                      </p>
                    )}
                  </div>

                  {/* Engine Torque */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Engine Torque (Nm)
                    </label>
                    <input
                      {...register("engine_torque", {
                        required: "Engine Torque is required",
                      })}
                      type="text"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Engine Torque"
                      value={formData.engine_torque}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          engine_torque: e.target.value,
                        })
                      }
                    />
                    {errors.engine_torque && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.engine_torque.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Features and Description */}
                <div className="grid grid-cols-1 gap-6 mt-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Features
                    </label>
                    <textarea
                      {...register("features")}
                      rows={3}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="List the vehicle features"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      {...register("description")}
                      rows={3}
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Detailed description of the vehicle"
                    />
                  </div>
                </div>

                <FormSection title="Documents">
                  <div className="grid grid-cols-1">
                    <DocumentUpload
                      label="RC Document"
                      name="rcUpload"
                      register={register}
                      required
                      error={!!errors.rcUpload}
                      errorMessage="RC document is required"
                      onUrlChange={setRcUrl}
                    />
                    {/* <DocumentUpload
                      label="Aadhaar Card"
                      name="aadhaarUpload"
                      register={register}
                      error={!!errors.aadhaarUpload}
                      errorMessage="Aadhaar card is required"
                      onUrlChange={setAadhaarUrl}
                    /> */}
                  </div>
                </FormSection>
              </div>
              {/* </div> */}
              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Add Vehicle"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function FormSection({ title, children }) {
  return (
    <div className="bg-gray-50 p-6 rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center p-4">
        {title}
      </h2>
      {children}
    </div>
  );
}

function DocumentUpload({
  label,
  name,
  register,
  required = false,
  error,
  errorMessage,
  onUrlChange,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [fileName, setFileName] = useState("");

  const handleUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileName(file.name);
    setIsUploading(true);
    setUploadStatus("uploading");

    try {
      let compressedFile = file;
      if (file.type.includes("image")) {
        compressedFile = await imageCompression(file, {
          maxSizeMB: 0.1,
          useWebWorker: true,
        });
      }

      const formData = new FormData();
      formData.append("file", compressedFile);

      const response = await axios.post(
        "./api/upload",
        formData
      );
      onUrlChange(response.data);
      setUploadStatus("success");
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadStatus("error");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700 text-center">
        {label}
      </label>
      <div className="relative">
        <input
          id={name} // Added ID for label targeting
          type="file"
          className="sr-only"
          {...(register ? register(name) : {})} // Apply register if provided
          onChange={handleUpload}
          accept="image/*,application/pdf"
          disabled={isUploading}
        />
        <div
          className={`relative flex items-center justify-center border-2 border-dashed rounded-lg p-4 transition-colors ${
            uploadStatus === "error"
              ? "border-red-300 bg-red-50"
              : uploadStatus === "success"
              ? "border-green-300 bg-green-50"
              : "border-gray-300 hover:border-violet-400 bg-gray-50 hover:bg-violet-50"
          }`}
        >
          <label
            htmlFor={name}
            className="flex flex-col items-center cursor-pointer w-full py-4"
          >
            {uploadStatus === "uploading" ? (
              <div className="flex flex-col items-center">
                <Upload className="w-8 h-8 text-violet-500 animate-bounce" />
                <p className="mt-2 text-sm text-violet-600">Uploading...</p>
              </div>
            ) : uploadStatus === "success" ? (
              <div className="flex flex-col items-center">
                <Check className="w-8 h-8 text-green-500" />
                <p className="mt-2 text-sm text-green-600">Upload successful</p>
                <p className="text-xs text-green-500 mt-1">{fileName}</p>
              </div>
            ) : uploadStatus === "error" ? (
              <div className="flex flex-col items-center">
                <X className="w-8 h-8 text-red-500" />
                <p className="mt-2 text-sm text-red-600">Upload failed</p>
                <button
                  type="button"
                  className="mt-2 text-xs text-red-500 hover:text-red-600"
                  onClick={() => setUploadStatus("idle")}
                >
                  Try again
                </button>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <File className="w-8 h-8 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">
                  <span className="font-semibold text-violet-600">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  PDF or images up to 5MB
                </p>
              </div>
            )}
          </label>
        </div>
      </div>
      {error && <p className="text-sm text-red-600 mt-1">{errorMessage}</p>}
    </div>
  );
}
