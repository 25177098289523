import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Upload, X, Image as ImageIcon, GripHorizontal } from 'lucide-react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

export default function MultipleImageUpload({ onImagesChange, maxImages = 6 }) {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (!files) return;

    setIsUploading(true);
    setUploadProgress(0);

    const newImages = [];
    const totalFiles = Math.min(files.length, maxImages - images.length);

    for (let i = 0; i < totalFiles; i++) {
      const file = files[i];
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.5,
          useWebWorker: true,
        });

        const formData = new FormData();
        formData.append('file', compressedFile);

        const response = await axios.post('https://patelsanghmp.com/upload', formData);
        const imageUrl = response.data.filename;

        newImages.push({ url: imageUrl, name: file.name });
        setUploadProgress(((i + 1) / totalFiles) * 100);
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }

    setImages((prev) => [...prev, ...newImages]);
    onImagesChange([...images, ...newImages].map((img) => img.url));
    setIsUploading(false);
    setUploadProgress(0);
  };

  const removeImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    onImagesChange(newImages.map((img) => img.url));
  };

  const reorderImages = (result) => {
    if (!result.destination) return;

    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImages(items);
    onImagesChange(items.map((img) => img.url));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">Vehicle Images</h3>
        <span className="text-sm text-gray-500">
          {images.length}/{maxImages} images
        </span>
      </div>

      {/* Upload Button */}
      {images.length < maxImages && (
        <div className="relative">
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="sr-only"
            id="multiple-images"
            disabled={isUploading}
          />
          <label
            htmlFor="multiple-images"
            className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 border-gray-300 hover:border-violet-400"
          >
            {isUploading ? (
              <div className="space-y-2 text-center">
                <Upload className="mx-auto h-8 w-8 text-violet-500 animate-bounce" />
                <div className="text-sm text-gray-600">
                  Uploading... {Math.round(uploadProgress)}%
                </div>
                <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-violet-500 transition-all duration-300"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
              </div>
            ) : (
              <div className="space-y-2 text-center">
                <Upload className="mx-auto h-8 w-8 text-gray-400" />
                <div className="text-sm text-gray-600">
                  <span className="text-violet-600 font-semibold">Click to upload</span> or drag and
                  drop
                </div>
                <p className="text-xs text-gray-500">PNG, JPG up to 5MB each</p>
              </div>
            )}
          </label>
        </div>
      )}

      {/* Image Grid with Drag and Drop */}
      <DragDropContext onDragEnd={reorderImages}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
            >
              {images.map((image, index) => (
                <Draggable key={image.url} draggableId={image.url} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="relative group aspect-square"
                    >
                      <img
                        src={image.url}
                        alt={`Vehicle ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                        <div {...provided.dragHandleProps} className="absolute top-2 left-2">
                          <GripHorizontal className="w-5 h-5 text-white" />
                        </div>
                        <button
                          onClick={() => removeImage(index)}
                          className="absolute top-2 right-2 p-1 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                        >
                          <X className="w-4 h-4 text-white" />
                        </button>
                        {index === 0 && (
                          <span className="absolute bottom-2 left-2 px-2 py-1 bg-violet-500 text-white text-xs rounded">
                            Thumbnail
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {images.length > 0 && (
        <p className="text-sm text-gray-500 mt-2">
          * First image will be used as thumbnail. Drag and drop to reorder images.
        </p>
      )}
    </div>
  );
}
