import React from 'react';
import { useTranslation } from 'react-i18next';


interface SpecificationCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
}

export default function SpecificationCard({ icon, label, value }: SpecificationCardProps) {
  const { t } = useTranslation();
  const translateField = (field) => {
    if (!field) return '';
    // Check if translation exists, else return the original field
    return t(field, { defaultValue: field });
  };
  if (!value) return null;

  return (
    <div className="flex items-center space-x-3">
      <div className="bg-blue-50 rounded-lg text-blue-600">
        {icon}
      </div>
      <div>
        <div className="text-xs sm:text-sm text-gray-500">{label}</div>
        <div className="text-xs sm:text-sm font-medium text-gray-900">{translateField(value)}</div>
      </div>
    </div>
  );
}