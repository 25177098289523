import React from 'react';
import { Check, X } from 'lucide-react';
import { useTranslation } from "react-i18next";

interface DocumentStatusProps {
  documents: {
    rc?: string;
    aadhaar?: string;
  };
}

export default function DocumentStatus({ documents }: DocumentStatusProps) {
  const { t } = useTranslation();

  if (!documents) return null;

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">{t("Document Status")}</h2>
      <div className="grid grid-cols-2 gap-6">
        {/* RC Document Status */}
        <div className="flex items-center space-x-3">
          <div className={`p-2 rounded-lg ${documents.rc ? 'bg-green-50 text-green-600' : 'bg-red-50 text-red-600'}`}>
            {documents.rc ? <Check className="w-5 h-5" /> : <X className="w-5 h-5" />}
          </div>
          <div>
            <div className="text-sm text-gray-500">{t("RC Document")}</div>
            <div className="font-medium text-gray-900">
              {documents.rc ? t("Available") : t("Not Available")}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
