import React from "react";
import { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "@fortawesome/fontawesome-svg-core/styles.css";
// import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { importAllImages } from "../../utils/importAllImages"; // Import the utility function
import { truckData, testimonialData } from "../../data";
import Carousel from "../../components/Carousels";
import icon1 from "../../assets/Icons/VeryLightTruck.png";
import icon2 from "../../assets/Icons/MediumTruck.png";
import icon3 from "../../assets/Icons/LightTruck.png";
import icon4 from "../../assets/Icons/HeavyTruck.png";
import category1 from "../../assets/category1.png";
import category2 from "../../assets/category2.png";
import category3 from "../../assets/category3.png";
import category4 from "../../assets/category4.png";
import category5 from "../../assets/category5.png";
import category6 from "../../assets/category6.png";
import vehicleData from "./vehicleData.json";
import ProductSkeleton from "./ProductSkeleton";
import SeachComponent from "./SearchBar/SeachComponent";
import InquiryForm from "../product/InquiryForm";
import { Trans } from "react-i18next";
import FAQ from "../../components/FAQ";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";

const images = importAllImages(
  require.context("../../assets/logo", false, /\.(png|jpe?g|svg)$/)
);
const logo = [icon1, icon3, icon2, icon4];

const importAllImagesProduct = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const imageName = item.replace("./", "").replace(/\.[^/.]+$/, ""); // Remove './' and the file extension
    images[imageName.replace(/_/g, " ")] = requireContext(item); // Map title with spaces
  });
  return images;
};
const ProductImg = importAllImagesProduct(
  require.context("../../assets/product", false, /\.(png|jpe?g|svg)$/)
);
function Home() {
  return (
    <div className="" id="home">
      <Carousel />
      <SeachComponent />
      <ProductList />
      <LogoCarousel />
      <FAQ />
    </div>
  );
}

const Tranding = ({ category, title, product }) => {
  // If product image is not a string, use the thumbnail as the image
  if (typeof product.image !== "string") {
    product.image = product.thumbnail;
  }
  const [isInquiryOpen, setInquiryOpen] = useState(false);

  if (product.sold) {
    return (
      <div className="p-4 w-full">
        <div className="relative block h-48 rounded overflow-hidden">
          {/* Sold Badge */}
          {product.sold && (
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex items-center justify-center">
              <span className="text-white text-xl font-bold uppercase">
                Sold
              </span>
            </div>
          )}

          <div className={`block w-full h-full cursor-not-allowed opacity-60`}>
            <motion.img
              alt={`Image of ${product.name}`}
              className="object-cover object-center w-full h-full block"
              src={product.image} // Use product image
              transition={{ duration: 0.3 }}
            />
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-2">
            {category}
          </h3>
          <div className="text-xl font-semibold text-[#103c64] ">
            {product.brand}&nbsp;{product.model}
          </div>
          <div className="text-xl font-semibold text-[#103c64] py-2">
            {product.manufacture_year}
          </div>

          {/* Display Product Cost */}
          <div className="text-xl font-semibold text-[#103c64]">
            ₹
            {product.cost
              ? product.cost.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "Not available"}
          </div>
          {/* Disabled Buttons */}
          <div className="flex justify-evenly items-center py-2">
            <div className="flex gap-2 w-full justify-between">
              <button
                className="px-4 py-2 w-full text-center text-white bg-gray-500 cursor-not-allowed rounded"
                disabled
              >
                Enquiry
              </button>
              <div
                className="px-4 py-2 w-full text-center text-white bg-gray-500 cursor-not-allowed rounded"
                to={`/product/${product._id.$oid}`}
                aria-disabled="true"
              >
                View
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 w-full">
      <Link
        to={`/product/${product._id.$oid}`}
        className="block relative h-48 rounded overflow-hidden"
      >
        <img
          alt={title}
          className="object-cover object-center w-full h-full block"
          src={product.image} // use product image
        />
      </Link>
      <div className="mt-4">
        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-2">
          {category}
        </h3>
        <div className="text-xl font-semibold text-[#103c64] ">
          {product.brand}&nbsp;{product.model}
        </div>
        <div className="text-xl font-semibold text-[#103c64] py-2">
          {product.manufacture_year}
        </div>

        {/* Display Product Cost */}
        <div className="text-xl font-semibold text-[#103c64]">
          ₹
          {product.cost
            ? product.cost.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "Not available"}
        </div>

        <div className="flex justify-evenly items-center py-2">
          <div className="flex gap-2 w-full justify-between">
            <button
              onClick={() => setInquiryOpen(true)}
              className="px-4 py-2 w-full text-center text-white bg-violet-600 border border-violet-600 rounded active:text-violet-500 hover:bg-slate-950 hover:text-violet-600 focus:outline-none focus:ring"
            >
              Enquiry
            </button>
            <Link
              className="px-4 py-2 w-full text-center text-violet-600 border border-violet-600 rounded hover:bg-violet-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring"
              to={`/product/${product._id.$oid}`}
            >
              View
            </Link>
          </div>
        </div>
      </div>
      <InquiryForm
        isOpen={isInquiryOpen}
        onClose={() => setInquiryOpen(false)}
        id={product._id.$oid}
      />
    </div>
  );
};

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Light Trucks");
  const [loading, setLoading] = useState(true);
  const categories = ["Light Trucks", "Medium Trucks", "Bus", "Tippers"];

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await axios.get("/api/vehicles", {
          params: { category: selectedCategory },
        });
        setProducts(response.data.slice(0, 8));
      } catch (error) {
        setProducts([]); // Set products to an empty array if there's an error
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchVehicles();
  }, [selectedCategory]);

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section className="text-gray-600 body-font 6" id="trendingvehicals">
      <h1 className="text-4xl text-gray-900 font-sans font-bold p-4 text-center">
        <Trans>Trending Vehicles</Trans>
      </h1>
      <div className="container px-5 mx-auto flex flex-wrap flex-col">
        <div className="grid md:grid-cols-4 grid-cols-2 mx-auto flex-wrap mb-4 gap-6 ">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleClick(category)}
              className={`px-3 cursor-pointer md:px-4 text-xs md:text-base py-1 sm:w-auto rounded-md justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider ${
                selectedCategory === category
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-indigo-500 border-indigo-500"
              }`}
            >
              <img src={logo[index]} alt="" className="size-10 mr-2" />
              <div className="text-center w-full">
                {category === "Light Trucks"
                  ? "Mini Trucks"
                  : category === "Medium Trucks"
                  ? "Trucks"
                  : category}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container md:px-5 flex items-center justify-center md:py-8 mx-auto w-full">
        {loading ? (
          <ProductSkeleton />
        ) : (
          <div className="grid xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 -m-4 w-full">
            {products?.map((product, index) => (
              <div to={`/product/${index}`} key={index} className="w-full p-2">
                <Tranding
                  category={product.brand}
                  title={product.name}
                  product={product}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

// const LogoCarousel = () => {
//   const [activeIdx, setActiveIdx] = useState(0);
//   const logoCount = Object.keys(images).length;
//   const visibleLogos = 5; // Number of logos visible at once

//   // Autoplay feature: change logo every 3 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1)); // Ensure we only loop through visible logos
//     }, 3000);
//     return () => clearInterval(interval);
//   }, [logoCount]);

//   const nextClick = () => {
//     setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
//   };

//   const prevClick = () => {
//     setActiveIdx(
//       (prev) =>
//         (prev - 1 + (logoCount - visibleLogos + 1)) %
//         (logoCount - visibleLogos + 1)
//     );
//   };

//   return (
//     <div className="bg-white py-12 px-4 md:px-8" id="brands">
//       <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
//         Our Trusted Brands
//       </h1>
//       <div className="relative flex items-center justify-center w-full overflow-hidden mt-10">
//         <div className="relative w-full max-w-7xl mx-auto">
//           {/* Carousel Content */}
//           <div
//             className="flex transition-transform duration-500 ease-in-out"
//             style={{
//               transform: `translateX(-${(activeIdx / logoCount) * 100}%)`,
//               width: `${(logoCount / visibleLogos) * 100}%`,
//             }}
//           >
//             {Object.keys(images).map((key, index) => (
//               <div
//                 key={index}
//                 className="flex-none px-6"
//                 style={{ width: `${100 / logoCount}%` }}
//               >
//                 {/* Dynamic URL based on image name */}
//                 <Link
//                   to={`/search?brand=${encodeURIComponent(key).replace(
//                     ".png",
//                     ""
//                   )}`}
//                 >
//                   <img
//                     src={images[key]}
//                     alt={`Logo ${index + 1}`}
//                     className="w-full h-32 object-contain mx-auto"
//                   />
//                 </Link>
//               </div>
//             ))}
//           </div>

//           {/* Previous Button */}
//           <button
//             className="absolute left-0 top-1/2 transform -translate-y-1/2  rounded-full p-3 text-gray-700  transition-all ease-in-out"
//             onClick={prevClick}
//           >
//             <ChevronLeft />
//           </button>

//           {/* Next Button */}
//           <button
//             className="absolute right-0 top-1/2 transform -translate-y-1/2  rounded-full p-3 text-gray-700  transition-all ease-in-out"
//             onClick={nextClick}
//           >
//             <ChevronRight />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const LogoCarousel = () => {
//   const [activeIdx, setActiveIdx] = useState(0);
//   const logoCount = Object.keys(images).length;
//   const visibleLogos = 5;

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
//     }, 3000);
//     return () => clearInterval(interval);
//   }, [logoCount]);

//   const nextClick = () => {
//     setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
//   };

//   const prevClick = () => {
//     setActiveIdx(
//       (prev) =>
//         (prev - 1 + (logoCount - visibleLogos + 1)) %
//         (logoCount - visibleLogos + 1)
//     );
//   };

//   return (
//     <div className="bg-white py-12 px-4 md:px-8" id="brands">
//       <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
//         Our Trusted Brands
//       </h1>
//       <div className="relative flex items-center justify-center w-full overflow-hidden mt-10">
//         {/* Previous Button */}
//         <button
//           className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 shadow-lg rounded-full p-3 text-gray-700 bg-gray-200 hover:bg-gray-300 transition-all ease-in-out"
//           onClick={prevClick}
//         >
//           <ChevronLeft />
//         </button>

//         <div className="relative w-full max-w-7xl mx-auto">
//           {/* Carousel Content */}
//           <div
//             className="flex transition-transform duration-500 ease-in-out"
//             style={{
//               transform: `translateX(-${(activeIdx / logoCount) * 100}%)`,
//               width: `${(logoCount / visibleLogos) * 100}%`,
//             }}
//           >
//             {Object.keys(images).map((key, index) => (
//               <div
//                 key={index}
//                 className="flex-none px-6"
//                 style={{ width: `${100 / logoCount}%` }}
//               >
//                 <Link
//                   to={`/search?brand=${encodeURIComponent(key).replace(
//                     ".png",
//                     ""
//                   )}`}
//                 >
//                   <img
//                     src={images[key]}
//                     alt={`Logo ${index + 1}`}
//                     className="w-full h-32 object-contain mx-auto"
//                   />
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Next Button */}
//         <button
//           className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 shadow-lg rounded-full p-3 text-gray-700 bg-gray-200 hover:bg-gray-300 transition-all ease-in-out"
//           onClick={nextClick}
//         >
//           <ChevronRight />
//         </button>
//       </div>
//     </div>
//   );
// };


const LogoCarousel = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const logoCount = Object.keys(images).length;

  // Determine the number of visible logos based on screen size
  const getVisibleLogos = () => {
    if (window.innerWidth >= 1024) return 5; // Desktop
    if (window.innerWidth >= 768) return 3; // Tablet
    return 1; // Mobile
  };

  const [visibleLogos, setVisibleLogos] = useState(getVisibleLogos());

  useEffect(() => {
    // Update visible logos on window resize
    const handleResize = () => setVisibleLogos(getVisibleLogos());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Auto-scroll the carousel
    const interval = setInterval(() => {
      setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, [logoCount, visibleLogos]);

  const nextClick = () => {
    setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
  };

  const prevClick = () => {
    setActiveIdx(
      (prev) =>
        (prev - 1 + (logoCount - visibleLogos + 1)) %
        (logoCount - visibleLogos + 1)
    );
  };

  return (
    <div className="bg-white py-12 px-4 md:px-8" id="brands">
      <h1 className="text-2xl md:text-4xl font-extrabold text-center text-gray-800 mb-8">
        Our Trusted Brands
      </h1>
      <div className="relative flex items-center justify-center w-full overflow-hidden mt-10">
        {/* Previous Button */}
        <button
          className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 z-10  rounded-full p-2 md:p-10 text-gray-900 transition-all ease-in-out"
          onClick={prevClick}
        >
          <ChevronLeft size={30} />
        </button>

        <div className="relative w-full max-w-7xl mx-auto">
          {/* Carousel Content */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${(activeIdx / logoCount) * 100}%)`,
              width: `${(logoCount / visibleLogos) * 100}%`,
            }}
          >
            {Object.keys(images).map((key, index) => (
              <div
                key={index}
                className="flex-none px-2 md:px-6"
                style={{ width: `${100 / logoCount}%` }}
              >
                <Link
                  to={`/search?brand=${encodeURIComponent(key).replace(
                    ".png",
                    ""
                  )}`}
                >
                  <img
                    src={images[key]}
                    alt={`Logo ${index + 1}`}
                    className="w-full h-20 md:h-32 object-contain mx-auto"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Next Button */}
        <button
          className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 z-10rounded-full p-2 md:p-3 text-gray-900 transition-all ease-in-out"
          onClick={nextClick}
        >
          <ChevronRight size={30} />
        </button>
      </div>
    </div>
  );
};





export default Home;
