import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ExternalLink, MessageCircle } from "lucide-react";
import InquiryForm from "../../features/product/InquiryForm";

interface ProductCardProps {
  product: {
    _id: { $oid: string },
    brand: string,
    name: string,
    image: string,
    category: string,
    cost?: number, // Optional cost field
    thumbnail?: string, // Optional thumbnail field for fallback
  };
}

export function ProductCard({ product }: ProductCardProps) {
  const [isInquiryOpen, setInquiryOpen] = useState(false);

  // Fallback for image if not available
  if (typeof product.image !== "string") {
    product.image = product.thumbnail || ""; // Default to empty if no thumbnail
  }
  if (product.sold) {
    return (
      <div className="relative group bg-white rounded-xl shadow-lg overflow-hidden opacity-60 cursor-not-allowed">
        {/* Image Container */}
        <div className="aspect-square overflow-hidden rounded-t-xl relative">
          <motion.img
            src={product.image}
            alt={`Image of ${product.name}`}
            className="w-full h-full object-cover"
            transition={{ duration: 0.3 }}
          />
          {/* Sold Overlay */}
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex items-center justify-center">
            <span className="text-white text-xl font-bold uppercase">Sold</span>
          </div>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="mb-3">
            <span className="inline-block px-3 py-1 text-xs font-medium bg-gray-100 text-gray-800 rounded-full">
              {product.brand}
            </span>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            {product.brand}&nbsp;{product.model}&nbsp;{product.manufacture_year}
          </h3>
          <p className="text-sm text-gray-500 mb-4">
            {product.category === "Light Trucks"
              ? "Mini Trucks"
              : product.category}
          </p>

          {/* Product Cost (Price) */}
          {product.cost && (
            <p className="text-lg font-semibold text-gray-600 mb-4">
              ₹{product.cost.toLocaleString()}{" "}
              {/* Format the cost as currency */}
            </p>
          )}

          {/* Buttons (None for Sold Products) */}
          <div className="flex gap-3">
            <button
              disabled
              className="flex-1 flex items-center justify-center gap-2 px-6 py-3 bg-gray-500 text-white rounded-lg cursor-not-allowed"
            >
              <span>Enquiry</span>
            </button>
            <button
              disabled
              className="flex-1 flex items-center justify-center gap-2 px-6 py-3 bg-gray-500 text-white rounded-lg cursor-not-allowed"
            >
              <span>View</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Link to={`/product/${product._id.$oid}`}>
        <motion.div
          className="group relative bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 overflow-hidden"
          whileHover={{ scale: 1.05 }}
        >
          {/* Image Container */}
          <div className="aspect-square overflow-hidden rounded-t-xl">
            <motion.img
              src={product.image}
              alt={`Image of ${product.name}`}
              className="w-full h-full object-cover"
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="mb-3">
              <span className="inline-block px-3 py-1 text-xs font-medium bg-violet-100 text-violet-800 rounded-full">
                {product.brand}
              </span>
            </div>
            {/* <pre> */}
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {product.brand}&nbsp;{product.model}&nbsp;
            </h3>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {product.manufacture_year}
            </h3>
            {/* </pre> */}
            <p className="text-sm text-gray-500 mb-4">
              {product.category === "Light Trucks"
                ? "Mini Trucks"
                : product.category}
            </p>

            {/* Product Cost (Price) */}
            {product.cost && (
              <p className="text-lg font-semibold text-violet-600 mb-4">
                ₹{product.cost.toLocaleString()}{" "}
                {/* Format the cost as currency */}
              </p>
            )}

            {/* Action Buttons */}
            <div className="flex gap-3">
              <button
                onClick={() => setInquiryOpen(true)}
                className="flex-1 flex items-center justify-center gap-2 px-6 py-3 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors"
              >
                <MessageCircle className="w-5 h-5" />
                <span>Enquiry</span>
              </button>
              <Link
                to={`/product/${product._id.$oid}`}
                className="flex-1 flex items-center justify-center gap-2 px-6 py-3 border border-violet-600 text-violet-600 rounded-lg hover:bg-violet-50 transition-colors"
              >
                <ExternalLink className="w-5 h-5" />
                <span>View</span>
              </Link>
            </div>
          </div>
        </motion.div>
      </Link>

      {/* Inquiry Form Modal */}
      <InquiryForm
        isOpen={isInquiryOpen}
        onClose={() => setInquiryOpen(false)}
        id={product._id.$oid}
      />
    </>
  );
}
