import React from "react";

const FAQ = () => {
  const questions = [
    {
      question: "What types of vehicles can I sell on Gaadi Market?",
      answer:
        "You can sell trucks, mini trucks, buses, and trippers on Gaadi Market. We welcome all vehicles that are in good condition and meet our platform's guidelines.",
    },
    {
      question: "How do I list my vehicle for sale on Gaadi Market?",
      answer:
        "To list your vehicle, simply sign up for an account, fill in the vehicle details, upload photos, and set your price. Your listing will be reviewed before it goes live.",
    },
    {
      question: "Is there a fee for listing my vehicle on Gaadi Market?",
      answer:
        "No, there is no fee for listing your vehicle on Gaadi Market. It's completely free to post your vehicle.",
    },
    {
      question: "How can I get my vehicle noticed by potential buyers?",
      answer:
        "To increase visibility, ensure your listing has high-quality photos and a detailed description. You can also choose to feature your listing for an additional fee.",
    },
    {
      question:
        "Can I edit my vehicle listing after it’s live on Gaadi Market?",
      answer:
        "Yes, you can edit your listing at any time, including updating photos, changing the price, or modifying the vehicle description.",
    },
    {
      question: "How do I handle payment and delivery once my vehicle is sold?",
      answer:
        "Once your vehicle is sold, we provide a secure payment gateway and assist with the transfer of ownership and delivery details. Payments are made through a secure online platform.",
    },
    {
      question: "Do I need to provide a warranty for the vehicle I sell?",
      answer:
        "While providing a warranty is optional, it can enhance your listing's appeal. We recommend providing a brief description of the vehicle's condition and any warranties available.",
    },
    {
      question:
        "Can I sell a vehicle with outstanding finance on Gaadi Market?",
      answer:
        "No, you cannot list a vehicle with outstanding finance. The vehicle must be fully paid off before it can be listed for sale.",
    },
    {
      question: "Is Gaadi Market free to use for sellers?",
      answer:
        "Yes! Listing your vehicle on Gaadi Market is free for all users. There are no fees for basic listings.",
    },
    {
      question: "Can I sell my vehicle internationally on Gaadi Market?",
      answer:
        "Gaadi Market primarily focuses on domestic sales. However, international buyers can browse listings. For international transactions, we recommend confirming payment and delivery methods in advance.",
    },
    {
      question:
        "How do I know if my vehicle listing is getting viewed by potential buyers?",
      answer:
        "You can track the performance of your listing through your seller dashboard, which provides insights on the number of views, inquiries, and potential buyers.",
    },
    {
      question: "Can I cancel my vehicle listing after it's posted?",
      answer:
        "Yes, you can remove or cancel your listing at any time through your account settings. However, any paid features associated with the listing are non-refundable.",
    },
    {
      question: "How do I contact a buyer after my vehicle is sold?",
      answer:
        "Once a vehicle is sold, you will be able to communicate with the buyer through Gaadi Market's secure messaging system to arrange for payment and delivery details.",
    },
    {
      question: "Can I offer financing options to buyers on Gaadi Market?",
      answer:
        "Gaadi Market does not directly offer financing options, but we provide a platform for buyers to reach out to their preferred financial institutions. Sellers can mention available financing options in their vehicle listings.",
    },
    {
      question: "Does Gaadi Market offer vehicle inspections for sellers?",
      answer:
        "Gaadi Market does not provide physical inspections for sellers. However, we recommend that sellers provide as much detail and accurate information about the vehicle's condition as possible in their listing.",
    },
  ];

  return (
    <div className="dark:bg-gray-800 min-h-screen">
      <div className="w-full px-4 mx-auto py-12 dark:bg-transparent dark:text-gray-200">
        <h3 className="text-2xl font-bold text-center text-gray-800 dark:text-gray-100">
          Frequently Asked Questions - Gaadi Market
        </h3>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-6 ">
          {questions.map((faq, index) => (
            <details key={index} className=" py-2">
              <summary className="flex items-center justify-between text-base font-medium cursor-pointer text-gray-800 dark:text-gray-100">
                <span>{faq.question}</span>
                <span className="transition-transform group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shape-rendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                    className="dark:stroke-gray-400"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="mt-3 text-base text-gray-600 dark:text-gray-300">
                {faq.answer}
              </p>
            </details>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
